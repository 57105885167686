<template>
  <div style="background:#f9f9f9;height: 571px;width: 100%;">
    <div class="container">
      <img @click="back" class="back" src="../assets/images/back.png" alt="" />
      <p class="mymsg">家庭联系人</p>
    </div>
    <van-tabs
      v-model="active"
      sticky
      title-active-color="#5ca5ff"
      color="#5ca5ff"
      class="daohang"
      @click="onClick"
    >
      <van-tab title="申请单">
        <button
          class="btn"
          click="shenqing"
          v-for="(item, index) in shenqingList"
          :key="index"
          @click="shenqingBtn(index)"
        >
          <strong>{{ item.patname }}</strong>
          <a>{{ item.orderlist.AppDeptCode }}</a>
        </button>
      </van-tab>
      <van-tab title="报告单">
        <button
          class="btn"
          v-for="item in baogaoList"
          :key="item.DocumentID"
          @click="baogaoDetail(item.DocumentID)"
        >
          <span class="baogao">{{ item.DocumentTitle }}</span>
          <a>{{ item.UpdateDate }}</a>
        </button>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { Dialog } from 'vant'
import {
  // getFamily,
  getFamilyOrder,
  getFamilyOrderDetail,
  getFamilyOrderDetailSelect
  // getFamilyOrderJiuzhen
} from '../services/home'
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  data() {
    return {
      active: 0,
      baogaoList: [],
      visitNumber: '',
      shenqingList: []
    }
  },
  async created() {
    // const pid = localStorage.getItem('patientId')
    // const mine = JSON.parse(localStorage.getItem('mine'))
    // const fojz = await getFamilyOrderJiuzhen(mine.id, mine.username)
    // console.log(fojz.data.rows)
    // 分离的话用这种
    //  const res = await getFamily(this.$route.query.user_id)
    // if (res.data.code === 200) {
    //   this.list = res.data.data.rows
    // } else {
    //   return res.data.msg
    // }

    await getFamilyOrder(this.$route.query.patientId).then(res => {
      const result = res.data.data.rows
      this.shenqingList = result
      result.forEach((e, i) => {
        res.data.data.rows[i].orderlist = JSON.parse(e.orderlist)
      })
      this.list = res.data.data.rows
    })
    var now = new Date()
    function plus0(number) {
      return number < 10 ? '0' + number : number
    }
    var documentTypeArray = [
      '01001',
      '01002',
      '02001',
      '02002',
      '02003',
      '02004',
      '02005',
      '02006'
    ]
    var orderListRes = await getFamilyOrderDetail(
      '<Request>' +
        '<TradeCode>1104</TradeCode>' +
        '<ExtOrgCode></ExtOrgCode>' +
        '<ClientType></ClientType>' +
        '<HospitalId></HospitalId>' +
        '<ExtUserID>wechat002</ExtUserID>' +
        '<CardType></CardType>' +
        '<PatientCard></PatientCard>' +
        '<PatientID>' +
        this.$route.query.patientId +
        '</PatientID>' +
        '<StartDate>' +
        (now.getFullYear() - 1) +
        '-' +
        plus0(now.getMonth() + 1) +
        '-' +
        plus0(now.getDate()) +
        '</StartDate>' +
        '<EndDate>' +
        now.getFullYear() +
        '-' +
        plus0(now.getMonth() + 1) +
        '-' +
        plus0(now.getDate()) +
        '</EndDate>' +
        '<TransactionId></TransactionId>' +
        '<AdmNo></AdmNo>' +
        '</Request>'
    )
    if (orderListRes.data.Response.ResultCode === '0') {
      var orderList = orderListRes.data.Response.Orders.Order
      // console.log(orderList)
    }

    for (var j in orderList) {
      for (var i = 0; i < documentTypeArray.length; i++) {
        var baogaoListRes = await getFamilyOrderDetailSelect(
          '<Request>' +
            '<Header>' +
            '<SourceSystem></SourceSystem> ' +
            '<MessageID></MessageID>' +
            '</Header>' +
            '<Body>' +
            '<DocumentRetrievalRt>' +
            '<PATPatientID>' +
            this.$route.query.patientId +
            '</PATPatientID>' +
            '<PAADMVisitNumber>' +
            orderList[j].AdmNo +
            '</PAADMVisitNumber> ' +
            '<DocumentType>' +
            documentTypeArray[i] +
            '</DocumentType>' +
            '<StartDate></StartDate>' +
            '<EndDate></EndDate>' +
            '<DocumentFormat>pdf</DocumentFormat>' +
            '</DocumentRetrievalRt>' +
            '</Body>' +
            '</Request>'
        )
        if (baogaoListRes.data.Response.Body.ResultCode === '0') {
          var baogaoListArr =
            baogaoListRes.data.Response.Body.DocumentRetrievalRp.Documents
              .Document
          this.baogaoList = baogaoListArr
          localStorage.setItem('visitNumber', orderList[j].AdmNo)
          localStorage.setItem(' documentType', documentTypeArray[i])
        }
      }
    }
    // if (this.shenqingList.length === 0) {
    //   Dialog.alert({
    //     message: '暂无申请单'
    //   }).then(() => {})
    // }
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    onClick(name, title) {
      if (title === '报告单') {
        if (this.baogaoList.length === 0) {
          Dialog.alert({
            message: '暂无报告单'
          }).then(() => {})
        }
      }
      if (title === '申请单') {
        if (this.shenqingList.length === 0) {
          Dialog.alert({
            message: '暂无申请单'
          }).then(() => {})
        }
      }
    },
    shenqingBtn(index) {
      location.href =
        'https://wx.weilaihos.com/app/main?hi=24DWKAH0D383&user_id=' +
        this.$route.query.user_id +
        '&num=' +
        index
    },
    baogaoDetail(docid) {
      localStorage.setItem('docid', docid)
      this.$router.push({
        name: 'baogaoDetail'
      })
    }
  }
}
</script>

<style scoped>
.back {
  z-index: 100;
}
.daohang {
  width: 100%;
  position: absolute;
  top: 55px;
}
.btn {
  width: 94%;
  height: 64px;
  background: #ffffff;
  box-shadow: 1px 1px 3px 0px #e9e9e9;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 3%;
  margin-top: 2%;
  padding: 10px;
  border: 1px solid gray;
}
.daohang .baogao {
  width: 239px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
.btn a {
  text-decoration: none;
  color: #5ca5ff;
}
.van-tabs__wrap {
  margin-bottom: 16px;
}
/deep/ .van-tabs__line {
  background-color: #5ca5ff !important;
}
/deep/ .van-tab__text--ellipsis {
  font-weight: bolder;
}
</style>
